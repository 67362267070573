
.av-footer {
    padding: 3rem 0;

    // Add shadow a lot of top footer
    box-shadow: 0 -0.05rem 1rem rgba($black, 0.15);

    &__brand {
        display: block;
        width: 10rem;
        margin-bottom: 1.5rem;

        @include media-breakpoint-down(lg) {
            width: 100%;
            text-align: center;
        }

        img {
            display: block;
            width: 100%;

            @include media-breakpoint-down(lg) {
                max-width: 300px;
                margin-left: auto;
                margin-right: auto;
            }
        }
    }

    &__info {
        margin-bottom: 0.75rem;

        &__title {
            font-size: 1.125rem;
            font-weight: 500;
            margin-bottom: 0.25rem;
            text-transform: uppercase;
        }

        &__content {
            color: $gray-600;
            font-size: 0.875rem;
            font-weight: 500;

            a {
                color: $gray-600;
                text-decoration: none;

                &:hover {
                    color: $blue-500;
                    text-decoration: underline;
                }
            }
        }
    }

    &__title {
        font-size: 1.25rem;
        font-weight: 500;
        margin-bottom: 0.5rem;
        text-transform: uppercase;
    }

    &__menu {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
            margin-bottom: 0.5rem;

            a {
                color: $gray-600;
                text-decoration: none;
                font-size: 0.875rem;
                font-weight: 500;

                &:hover {
                    color: $blue-500;
                    text-decoration: underline;
                }
            }
        }
    }
}
