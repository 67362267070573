.av-navbar {
    transition: transform 0.3s ease-in-out;

    // Add shadow
    box-shadow: 0 0.5rem 1rem rgba($black, 0.15);

    // Hide the navbar when scrolling down
    &.av-navbar--hidden {
        transform: translateY(-100%);
    }

    .navbar-brand {
        padding: 0;
        img {
            max-height: 40px;
        }
    }
}
