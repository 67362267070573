.av-our-store {
    padding: 2rem 0;

    &__subtitle, &__title, &__description {
        font-weight: 500;
        margin-bottom: 0.5rem;
        text-align: center;
    }

    &__subtitle {
        font-size: 1.25rem;
        color: #808080;
    }

    &__title {
        font-size: 2rem;
        position: relative;

        @include media-breakpoint-down(md) {
            font-size: 1.75rem;
        }
    }

    &__description {
        font-size: 1rem;
        margin-bottom: 2rem;
        max-width: 50vw;
        margin-left: auto;
        margin-right: auto;
    }

   &__nav {
       .nav-pills {
           border: none;
           display: flex;
              justify-content: center;
              margin-bottom: 1rem;
           gap: 0.5rem;

           .nav-item {
               .nav-link {
                   border: none;
                   display: flex;
                   align-items: center;
                   justify-content: center;
                   color: $dark;

                   &.active {
                       color: white;
                       background-color: $dark;
                   }
               }
           }
       }
   }

    &__carousel {
        position: relative;

        @include media-breakpoint-down(sm) {
            padding-bottom: 3rem;
        }

        .carousel-inner {
            padding: 1rem 0;
            z-index: 1;
        }

        &__image {
            overflow: hidden;
            box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);

            img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        &__content {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 100%;
            position: relative;

            @include media-breakpoint-down(sm) {
                margin-top: 1rem;
            }

            &__title {
                font-size: 2rem;
                font-weight: 500;
                margin-bottom: 0.5rem;
                position: relative;

                @include media-breakpoint-down(md) {
                    font-size: 1.75rem;
                }

                @include media-breakpoint-down(sm) {
                    font-size: 1.5rem;
                }

                &:after {
                    position: absolute;
                    content: '';
                    width: 50%;
                    height: 0.15rem;
                    background-color: $dark;
                    top: 100%;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }

            &__description {
                font-size: 1.25rem;
                max-width: 70%;
                text-align: center;

                @include media-breakpoint-down(md) {
                    font-size: 1rem;
                }

                @include media-breakpoint-down(sm) {
                    font-size: 0.875rem;
                }
            }
        }

        &__indicator {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;

            font-size: 1.25rem;
            line-height: 1;

            @include media-breakpoint-down(md) {
                font-size: 1rem;
            }

            @include media-breakpoint-down(sm) {

            }

            &__wrap {
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 0.5rem;
                z-index: 1;
                padding: 1rem 0;

                @include media-breakpoint-down(sm) {
                    padding: 0.5rem 0;
                    font-size: 0.875rem;
                }
            }

            &__prev, &__next {
                .btn-outline-dark {
                    text-decoration: none;
                    width: 7rem;

                    @include media-breakpoint-down(sm) {
                        width: 5rem;
                        font-size: 0.75rem;
                    }
                }
            }

            &__slide-pagination {
                margin-left: 0.5rem;
                margin-right: 0.5rem;
            }
        }
    }
}
