.av-contact-section {
    padding: 3rem 0;
    background-color: #f5f5f5;

    &__title {
        font-size: 2rem;
        font-weight: 500;
        margin-bottom: 0.5rem;
        text-align: center;

        @include media-breakpoint-down(md) {
            font-size: 1.75rem;
        }
    }

    &__description {
        font-size: 1rem;
        font-weight: 500;
        margin-bottom: 1rem;
        text-align: center;
        max-width: 75vw;
        margin-left: auto;
        margin-right: auto;
    }

    &__button {
        display: block;
        margin-left: auto;
        margin-right: auto;
        padding: 1rem 2rem;
        border-radius: 1rem;
        color: #fff;
        font-size: 1rem;
        font-weight: 500;
        text-decoration: none;
        text-align: center;
    }
}
