.av-tag {
    font-family: 'Be Vietnam Pro', sans-serif;
    text-transform: capitalize;
    text-align: center;
    padding: 0.5rem 1rem;
    background-color: $gray-200;
    border-radius: 1rem;
    color: $black;
    text-decoration: none;
}
