.av-parallax-scrolling {
    // Parallax scrolling images
    &__item {
        /* Set a specific height */
        min-height: 1280px;
        position: relative;
        overflow: hidden;

        &__bg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            /* Create the parallax scrolling effect */
            background-attachment: fixed;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            will-change: transform;
        }

        // Content includes title and description
        &__content {
            // Center the content
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 100%;
            padding: 0 1rem;
            color: #fff;
            text-align: center;

            // Text shadow
            text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);

            @include media-breakpoint-down(lg) {
                width: 100%;
            }

            &__title {
                font-size: 2rem;
                font-weight: 500;
                margin-bottom: 0.5rem;

                @include media-breakpoint-down(md) {
                    font-size: 1.5rem;
                }

                @include media-breakpoint-down(sm) {
                    font-size: 1rem;
                }
            }

            &__description {
                font-size: 1rem;
                font-weight: 500;
                margin-bottom: 2rem;
                max-width: 50vw;
                margin-left: auto;
                margin-right: auto;

                @include media-breakpoint-down(sm) {
                    max-width: 75vw;
                    font-size: 0.75rem;
                }
            }

            &__button {
                display: block;
                margin-left: auto;
                margin-right: auto;
                padding: 0.5rem 1rem;
                border-radius: 0.5rem;
                color: #fff;
                // Material black background color
                background-color: #212121;
                text-decoration: none;
                font-size: 1rem;
                font-weight: 500;
                transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out, transform 0.3s ease-in-out;

                @include media-breakpoint-down(sm) {
                    font-size: 0.75rem;
                }

                &:hover {
                    background-color: #333;
                    color: #fff;

                    // Scale the button
                    transform: scale(1.1);
                }
            }
        }
    }
}
