.av-top-news {
    display: flex;
    flex-direction: column;
    padding: 10px;
    border-radius: 0.25rem;
    color: $black;
    text-decoration: none;
    width: 100%;

    &:hover,
    &:focus {
        color: $primary;
        text-decoration: none;
    }

    .av-top-news__item {
        display: flex;
        flex-direction: row;
        margin-bottom: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid #eee;
    }

    .av-top-news__image {
        // It should be 30% of the parent width
        flex: 1;
        width: 30%;
        margin-right: 10px;
        border-radius: 0.25rem;
    }

    .av-top-news__content {
        // It should be 70% of the parent width
        flex: 2;

        .av-top-news__title {
            font-size: 16px;
            font-weight: bold;
            margin-bottom: 5px;
        }

        .av-top-news__description {
            font-size: 12px;
            color: #666;
            margin-bottom: 10px;
        }

        .av-top-news__date {
            font-size: 10px;
            color: #999;
            margin-top: 5px;
        }
    }
}
