.news-page {
    padding-top: 3rem;
    padding-bottom: 3rem;

    @include media-breakpoint-down(sm) {
        padding-top: 1rem;
        padding-bottom: 1rem;
    }

    .posts-wrapper {
        .av-news-block {
            margin-bottom: 1rem;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .sidebar {
        .av-top-news {
            list-style: none;
            padding: 0;
            margin-top: 20px;
        }

        .av-top-news li {
            margin-bottom: 10px;
        }

        .av-top-news a {
            text-decoration: none;
            color: #333;
        }

        .av-top-news a:hover {
            text-decoration: underline;
        }
    }
}
