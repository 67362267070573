@import "fonts";
@import "bootstrap/scss/bootstrap";

html, body {
    font-family: 'Be Vietnam Pro', sans-serif;
}

body {
    padding-top: 56px;
}

@import "partials/header";
@import "partials/footer";

@import "components/temp";

// Components
@import "components/av-parallax-scrolling";
@import "components/av-image-gallery";
@import "components/av-skills";
@import "components/av-dress-gallery";
@import "components/av-wedding-album-gallery";
@import "components/av-contact-section";
@import "components/av-sidebar-menu";
@import "components/av-image-box";
@import "components/av-scrolling-box";
@import "components/av-tag";
@import "components/av-news-block";
@import "components/av-carousel";
@import "components/av-mobile-album-block";
@import "components/av-top-news";
@import "components/av-our-store";


// Pages
@import "pages/wedding-album-single";
@import "pages/wedding-dress-single";
@import "pages/news-single";

@import "pages/contact-page";
@import "pages/news-page";


/* Cho màn hình Desktop */
.video-container {
    position: relative;
    width: 60%;
    padding-bottom: 33.75%; /* Tỷ lệ 16:9 (60% / 16 * 9) */
    padding-top: 30px;
    height: 0;
    overflow: hidden;
    margin: 1rem auto;

    iframe, object, embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    @include media-breakpoint-down(sm) {
        width: 100%;
        padding-bottom: 56.25%; /* Tỷ lệ 16:9 */
    }
}

.caller-button {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    right: 20px;
    bottom: 20px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    font-size: 24px;
    color: #fff;
    background-color: #34c759; // Green background
    border: none;
    text-decoration: none;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #2d9a57; // Darken the green background on hover
    }

    @keyframes pulse {
        0% {
            transform: scale(1);
            opacity: 0.5;
        }
        100% {
            transform: scale(1.5);
            opacity: 0;
        }
    }

    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-color: #34c759; // Same color as the button
        opacity: 0.5;
        transform: scale(1);
        animation: pulse 2s infinite;
    }

    .fas.fa-phone {
        margin: 0;
        color:white;
    }
}
