.av-image-box {
    display: block;
    position: relative;
    overflow: hidden;
    border-radius: 1rem;
    box-shadow: 0 0.5rem 1rem rgba($black, 0.15);
    transition: transform 0.3s ease-in-out;
    height: 300px;

    &:hover {
        transform: scale(1.05);
    }

    &__image {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 0.25rem;
    }

    &__overlay {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        transition: opacity 0.3s ease-in-out;
        z-index: 1;
    }

    &__title {
        color: $white;
        font-size: 1.25rem;
        font-weight: 500;
        margin-bottom: 0.5rem;
        text-transform: capitalize;

        // Text shadow
        text-shadow: 0 0.125rem 0.25rem rgba($black, 0.5);
        text-align: center;
        padding: 1rem;
    }




}
