.wedding-album-list-component {
    &--default {
        .wedding-album-list-component__item {
            &__image {
                width: 100%;
                height: 100%;
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                border-radius: 4px;
                box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
            }

            &__title {
                font-size: 1.25rem;
                font-weight: 500;
                text-align: center;
                margin-top: 0.5rem;
            }

            &:hover {
                .wedding-album-list-component__item__image {
                    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.8);
                }
            }
        }
    }

    &--style-1 {
        @extend .wedding-album-list-component--default;

        .wedding-album-list-component__item {
            &__description {
                text-align: center;
                font-size: 0.75rem;
            }
        }
    }

    &--style-2 {
        .wedding-album-list-component__item {
            //    Parallax image
            &__image {
                background-attachment: fixed;
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                height: 100vh;
                position: relative;
                width: 100%;
            }

            &__title {
                color: #fff;
                font-size: 2rem;
                font-weight: 500;
                left: 50%;
                position: absolute;
                text-align: center;
                top: 50%;
                transform: translate(-50%, -50%);
                width: 100%;
            }

            &__description {
                color: #fff;
                font-size: 1rem;
                font-weight: 500;
                left: 50%;
                position: absolute;
                text-align: center;
                top: 60%;
                transform: translate(-50%, -50%);
                width: 100%;
            }

            &__title, &__description {
                text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
            }
        }
    }
}

.empty-block-component {
    &--default {
        position: relative;
        width: 100%;
    }
}


.footer-component {
    &--default {
        background-color: #000;
        color: #fff;
        padding: 4rem 0;

        .footer-component__item {

            &__logo {
                width: 150px;
                height: 100%;
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;

                img {
                    display: block;
                    width: 100%;
                }
            }

            &__title {
                font-size: 1.25rem;
                font-weight: 500;
                margin-bottom: 0.5rem;
            }

            &__content {
                font-size: 0.75rem;
                font-weight: 500;
            }
        }
    }
}

.navbar-component {
    &--default {
        background-color: #fff;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
        padding: 1rem 0;



        .navbar-component__item {

            &__logo {
                width: 150px;
                height: 100%;
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;

                img {
                    display: block;
                    width: 100%;
                }
            }
            &__menu {
                font-size: 0.75rem;
                font-weight: 500;
                text-transform: uppercase;
                margin: 0;
                padding: 0;
                list-style: none;

                li {
                    display: inline-block;
                    margin-right: 1rem;

                    a {
                        color: #000;
                        text-decoration: none;
                    }
                }
            }
        }
    }
}
