@font-face {
    font-family: 'Be Vietnam Pro';
    src: url('../fonts/BeVietnamPro-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Be Vietnam Pro';
    src: url('../fonts/BeVietnamPro-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Be Vietnam Pro';
    src: url('../fonts/BeVietnamPro-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Be Vietnam Pro';
    src: url('../fonts/BeVietnamPro-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Be Vietnam Pro';
    src: url('../fonts/BeVietnamPro-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Be Vietnam Pro';
    src: url('../fonts/BeVietnamPro-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Be Vietnam Pro';
    src: url('../fonts/BeVietnamPro-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Be Vietnam Pro';
    src: url('../fonts/BeVietnamPro-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Be Vietnam Pro';
    src: url('../fonts/BeVietnamPro-ExtraBoldItalic.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Be Vietnam Pro';
    src: url('../fonts/BeVietnamPro-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Be Vietnam Pro';
    src: url('../fonts/BeVietnamPro-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Be Vietnam Pro';
    src: url('../fonts/BeVietnamPro-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Be Vietnam Pro';
    src: url('../fonts/BeVietnamPro-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}
