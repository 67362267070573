.av-sidebar-menu {
    .nav-link {
        // If it doesn't have class "disabled" and it's not active, set color to black
        &:not(.disabled):not(.active) {
            color: $black;
        }

        &.active {
            background-color: $gray-200;
            color: $black;
        }
    }
}
