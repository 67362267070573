.wedding-album-single {
    padding-top: 3rem;
    padding-bottom: 3rem;

    &--style-1 {
        .av-heading {
            font-family: 'Be Vietnam Pro', sans-serif;
            font-weight: 700;
            font-size: 2.5rem;
            line-height: 1.2;
            margin-bottom: 1rem;
            color: #000;
            text-transform: capitalize;

            @include media-breakpoint-down(sm) {
                font-size: 1.5rem;
            }
        }


        .wedding-album-single__images {
            &__item {
                margin-bottom: 1rem;
                &:last-child {
                    margin-bottom: 0;
                }

                img {
                    display: block;
                    width: 100%;
                    border-radius: 10px;
                    //box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);

                }
            }
        }

        .wedding-album-single__info {
            &__content {
                margin-bottom: 1rem;
            }

            &__tags {
                margin-bottom: 1rem;
                .av-tag {
                    margin-right: 0.5rem;
                    &:last-child {
                        margin-right: 0;
                    }
                }
            }

        }
    }

    &--style-2 {
        .av-heading {
            font-family: 'Be Vietnam Pro', sans-serif;
            font-weight: 700;
            font-size: 2.5rem;
            line-height: 1.2;
            margin-bottom: 1rem;
            color: #000;
            text-transform: capitalize;
            text-align: center;

            @include media-breakpoint-down(sm) {
                font-size: 1.5rem;
            }
        }


        .wedding-album-single__images {
            &__item {
                margin-bottom: 1rem;
                &:last-child {
                    margin-bottom: 0;
                }

                img {
                    display: block;
                    width: 100%;
                    //box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);

                }

                &--orientation {
                    &-vertical {
                        img {
                            max-width: 750px;
                            margin-left: auto;
                            margin-right: auto;
                        }
                    }

                    &-horizontal {

                    }

                    &-square {

                    }
                }
            }
        }

        .wedding-album-single__info {
            &__content {
                margin-bottom: 1rem;
                text-align: center;
            }

            &__tags {
                margin-bottom: 1rem;
                .av-tag {
                    margin-right: 0.5rem;
                    &:last-child {
                        margin-right: 0;
                    }
                }
            }

        }
    }
}
