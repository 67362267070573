.av-news-block {
    display: flex;
    flex-direction: column;
    padding: 10px;
    box-shadow: 0 0.5rem 1rem rgba($black, 0.15);
    border-radius: 0.25rem;
    color: $black;
    text-decoration: none;
    width: 100%;

    &:hover,
    &:focus {
        color: $primary;
        text-decoration: none;
    }

    .av-news-block__image {
        width: 100%;
        height: auto;
        max-width: 100%;
        margin-right: 10px;
        border-radius: 0.25rem;
    }

    .av-news-block__content {
        flex: 1;

        .av-news-block__title {
            font-size: 18px;
            font-weight: bold;
            margin-bottom: 5px;
        }

        .av-news-block__description {
            font-size: 14px;
            color: #666;
            margin-bottom: 10px;
        }

        .av-news-block__date {
            font-size: 10px;
            color: #999;
            margin-top: 5px;
        }
    }
}

.av-news-block-container {
    display: flex;
    align-items: stretch;
    /* Đảm bảo các flexbox con cùng chiều cao với nhau */
    margin-bottom: 1.5rem;
}
