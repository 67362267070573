.av-carousel {
    // Max Height 100vh
    max-height: 100vh;
    overflow: hidden;

    // Carousel
    .carousel {
        height: 100%;
        max-height: 100%;
        overflow: hidden;
        position: relative;
        background: #000;
        color: #fff;
        text-align: center;
        .carousel-inner {
            height: 100%;
            max-height: 100%;
            .carousel-item {
                height: 100%;
                max-height: 100%;
                img {
                    height: 100%;
                    max-height: 100%;
                    width: auto;
                    margin: 0 auto;
                }
            }
        }
    }
}
