.av-skills {
    padding: 3rem 0;
    background-color: #f5f5f5;

    &__subtitle {
        font-size: 1.25rem;
        font-weight: 500;
        margin-bottom: 0.5rem;
        text-align: center;
        color: #808080;
    }

    &__title {
        font-size: 2rem;
        font-weight: 500;
        margin-bottom: 0.5rem;
        text-align: center;
    }

    &__description {
        font-size: 1rem;
        font-weight: 500;
        margin-bottom: 2rem;
        text-align: center;
        max-width: 50vw;
        margin-left: auto;
        margin-right: auto;
    }

    &__items {
        display: flex;
        flex-wrap: wrap;
        margin: 0;
    }

    &__item {
        text-align: center;

        &__icon {
            font-size: 3rem;
            margin-bottom: 0.5rem;
        }

        &__title {
            font-size: 1.25rem;
            font-weight: 500;
            margin-bottom: 0.5rem;
        }

        &__description {
            font-size: 1rem;
            font-weight: 500;
        }
    }
}
