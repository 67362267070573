.av-scrolling-box {
    position: relative;
    height: 100%;

    &__content {
        position: absolute;
        top: 0;

        @include media-breakpoint-down(lg) {
            position: relative;
        }

        &--sticky {
            position: sticky;
            top: 56px;
            left: 0;
            right: 0;
            z-index: 999;
        }
    }
}
