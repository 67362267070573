.av-image-gallery {
    padding: 2rem 0;

    &__subtitle, &__title, &__description {
        font-weight: 500;
        margin-bottom: 0.5rem;
        text-align: center;
    }

    &__subtitle {
        font-size: 1.25rem;
        color: #808080;
    }

    &__title {
        font-size: 2rem;

        @include media-breakpoint-down(md) {
            font-size: 1.75rem;
        }
    }

    &__description {
        font-size: 1rem;
        margin-bottom: 2rem;
        max-width: 50vw;
        margin-left: auto;
        margin-right: auto;
    }

    &__images {
        margin: 0;

        &__item {
            flex: 0 0 33.333333%;
            padding: 0.5rem;
        }
    }

    &__image {
        display: block;
        width: 100%;
        margin-bottom: 1.5rem;
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);
        border-radius: 1rem;
        overflow: hidden;

        img {
            display: block;
            width: 100%;
        }
    }
}
